import { gql } from 'graphql-tag'
import { MEMBER_ACCESS_RIGHTS } from '../globals'
import { Toniebox } from './tonieboxes-query'

export const query = gql`
  query Dashboard($canBuyTunes: Boolean!, $canGetAudioCandy: Boolean!) {
    frontpage {
      tonieboxes(count: 2) {
        id
        name
        imageUrl
        householdId
      }
      contentTonies(count: 4) {
        id
        title
        imageUrl
        householdId
        series {
          name
          group {
            name
          }
        }
      }
      creativeTonies(count: 4) {
        id
        name
        imageUrl
        householdId
      }
      tunesItems(count: 10) @include(if: $canBuyTunes) {
        id
        title
        thumbnail
        salesId
        price {
          status
          currency
          centAmount
          strikeCentAmount
        }
      }
    }
    households {
      id
      access
      canLeave
      foreignCreativeTonieContent
      id
      image
      name
      ownerName
      tonieboxes {
        id
        name
        householdId
        imageUrl
      }
    }
    contentTokens(selection: "public", first: 10)
      @include(if: $canGetAudioCandy) {
      edges {
        node {
          id
          series {
            id
            name
          }
          languageUnicode
          subtitle
          thumbnail
          title
          token
        }
      }
    }
  }
`
export interface DashboardQuery {
  frontpage: {
    tonieboxes?: Toniebox[]
    creativeTonies?: Array<{
      id: string
      name: string
      live: boolean
      private: string
      imageUrl: string
      householdId: string
    }>
    contentTonies?: Array<{
      id: string
      title: string
      imageUrl: string
      householdId: string
      series?: {
        name: string
        group?: {
          name: string
        }
      }
    }>
    tunesItems?: Array<{
      id: string
      title: string
      thumbnail: string
      salesId: string
    }>
  }
  households: Array<{
    access: keyof typeof MEMBER_ACCESS_RIGHTS
    canLeave: boolean
    foreignCreativeTonieContent: boolean
    id: string
    image: string
    name: string
    ownerName: string
    tonieboxes: Toniebox[]
  }>
  contentTokens?: {
    edges: Array<{
      node: {
        id: string
        series: {
          id: string
          name: string
        }
        languageUnicode?: string
        subtitle: string
        thumbnail: string
        title: string
        token: string
      }
    }>
  }
}
