import React, { ReactNode } from 'react'
import {
  media,
  OmniButton,
  Icon,
  Headline1,
  Headline2,
  Headline3,
  TrackList,
  HorizontalScrollList,
  Paragraph,
  InfoBox,
  TagV2,
  TagListV2,
} from '@boxine/tonies-ui'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { InnerContainer, LayoutV2 } from '../../layouts/LayoutV2'
import { Text } from '../Typography'
import { AudioLibraryUrlState } from '../../utils/functions/buildAudioLibraryUrl'
import formatAudioTime from '../../utils/functions/formatAudioTime'
import { selectTunesSeriesName } from '../../utils/tunes-utils'

type InnerDetailpagesContainerProps = {
  isWebview?: boolean
}

export const InnerDetailpagesContainer = styled(
  InnerContainer
)<InnerDetailpagesContainerProps>`
  display: flex;
  /* We do not hide the backlink in webview=app on the free-content detail page,
     and therefore need the optional prop to change the flex behavior only on 
     AudioLibraryDetailPage
  */
  justify-content: ${props => (props.isWebview ? 'flex-end' : 'space-between')};
  /* weird offset need to emulate the old absolute position of the min age tag */
  margin-top: -5px;
  padding-right: 0;
`

export const PriceTitleWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
`

export const TunesGridTitle = styled.h2`
  display: block;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  padding-top: 0.5rem;
  font-weight: 700;
  font-family: ${props => props.theme.fonts.serif};
  margin-bottom: 0.25rem;
`

export const StyledCheck = styled(Icon)`
  width: 1rem;
  height: 1rem;
  position: relative;
  top: 0.25rem;
  right: 0.25rem;

  ${media.tabletL`
    position: unset;
    width: 1.5rem;
    height: 1.5rem;
    right: 1.5rem;
  `}
`

export const NoContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  /* To accommodate the layout for models like iphone 5S */
  margin-top: -0.5rem;

  ${media.mobileM`
    margin-top: unset;
  `}
`

export const NoContentYetHeadline = styled(Headline2)`
  text-align: center;
  font-weight: bold;
  font-size: 1.125rem;
  margin-bottom: 0.5rem;

  ${media.mobileM`
    margin-top: 2.5rem;
  `}

  ${media.tabletL`
    margin-top: unset;
    font-size: 1.5rem;
  `}
`

export const NoContentYetCopy = styled.span`
  text-align: center;
  font-weight: 300;
  font-family: ${props => props.theme.fonts.serif};
  font-size: 1rem;
  width: 17.625rem;
  margin-bottom: 1.5rem;

  ${media.mobileM`
    margin-bottom: 3.5rem;
  `}

  ${media.tabletL`
    width: unset;
  `}
`

export const FlexColumnCenter = css`
  /**Base Style to center elements that are placed on top of each other in a container */
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`

export const Layout = styled(LayoutV2)`
  background-color: ${props => props.theme.LightBlue};
  display: flex;
  flex-direction: column;
  flex: 1;

  ::before {
    background-color: ${props => props.theme.LightBlue};
  }
`

const ContentLength = styled(Text)`
  font-family: ${props => props.theme.fonts.serif};
  font-size: 0.875rem;
  font-weight: 300;
  margin-bottom: 0.25rem;
`

const ContentDescription = styled(Text)`
  font-family: ${props => props.theme.fonts.serif};
  font-size: 0.875rem;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 1.75rem;
  white-space: pre-wrap;

  ${media.tabletL`
    font-size: 1rem;
  `}
`

export const DetailPage = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  border-radius: 3rem 3rem 0 0;
  background-color: ${props => props.theme.White};
  max-width: ${props => props.theme.application.maxContentWidth};

  ${media.laptop`
    grid-template-columns: 50% auto;
  `}
`

export const Main = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const BackLink = styled(OmniButton)`
  position: relative;
  left: 0;
`

const Tracks = styled(TrackList)`
  padding-bottom: 2rem;
`

export const TopContainer = styled.div`
  position: relative;
  padding-right: 0;

  ${media.laptop`
    padding-right: 1rem;
    padding-left: 1rem;
  `}
`

export const ContentDetailContainer = styled.div`
  margin-top: 3.5rem;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${props => props.theme.White};
  border-radius: 2.25rem 2.25rem 0 0;
  padding-left: 1rem;
  padding-right: 1rem;

  ${media.laptop`
    margin-top: 5rem;
    border-radius: 3rem 3rem 0 0;
  `}
`

export const ThumbnailWrapper = styled.div`
  ${FlexColumnCenter}
  position: relative;
  top: -3rem;
  justify-self: center;
  width: 8.375rem;
  height: auto;
  margin-bottom: -1rem;

  ${media.tabletL`
    top: -4rem;
    width: 16.75rem;
  `}
`

export const DesktopViewport = styled.div`
  display: none;

  ${media.laptop`
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`

export const AssignmentDesktopWrapper = styled.div`
  margin-top: 2rem;
  display: none;

  ${media.laptop`
    display: block;
  `}
`

export const AssignmentWrapper = styled.div`
  margin-top: 2rem;
`

export const AssignedTonieWrapper = styled.div`
  position: relative;
`

export const AssignedHouseholdIcon = styled(Icon)`
  height: 2rem;
  width: 2rem;
  position: absolute;
  bottom: 4rem;
  right: -0.5rem;
  border-radius: 50%;
  box-shadow: 0 0.125rem 0 0 ${props => props.theme.GrayLighter};

  ${media.laptop`
    right: 0;
  `}

  path:nth-child(2) {
    fill: ${props => props.theme.White};
  }

  path:nth-child(3) {
    fill: ${props => props.theme.Gray};
  }
`

export const AssignButton = styled(OmniButton)`
  margin-top: 0.5rem;
`

export const HeaderLayout = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin: 0 0 2rem 0;

  ${media.laptop`
    margin: 0 0 2.5rem 0;
  `}

  > h2 {
    padding-left: 0;
  }

  > link,
  a {
    justify-self: end;
    align-self: start;
    padding-right: 0;

    > span {
      text-decoration: underline;
      text-underline-offset: 1px;
      color: ${props => props.theme.colors.primary};
      padding-top: 0.25rem;
    }
  }
`

const Title = styled(Headline1)`
  margin-top: -1rem;
  font-size: 1.125rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.25rem;

  ${media.tabletL`
    font-size: 1.5rem;
    margin-top: 0.5rem;
  `}

  ${media.laptop`
    margin-top: 1rem;
    text-align: left;
    margin-bottom: 0.5rem;
  `}
`

type AdditionalContentWrapperProps = {
  hasNoBackgroundColor?: boolean
  padding?: string
}

export const AdditionalContentWrapper = styled.div<AdditionalContentWrapperProps>`
  background-color: ${props =>
    props.hasNoBackgroundColor ? 'transparent' : props.theme.White};
  flex: 1;
  padding: ${props => (props.padding ? props.padding : '0.75rem 0 2rem 0')};

  ${media.tabletL`
    padding: ${props => (props.padding ? props.padding : '2.5rem 0 3rem 0')};
  `}
`

// We override the styles of the HorizontalScrollList to avoid the grey and green
// shadows to be hidden and the slider scrolling a bit vertically in its section.
// As the green shadow is wider than the grey one we need a bit more spacing on the
// slider for the owned content.
type StyledHorizontalScrollListProps = {
  isOwnedContentSlider?: boolean
  alwaysShowNavigation?: boolean
  transparent?: boolean
}

export const StyledHorizontalScrollList = styled(
  HorizontalScrollList
)<StyledHorizontalScrollListProps>`
  ul {
    padding: ${props =>
      props.isOwnedContentSlider ? '0.25rem 1rem' : '0.125rem 1rem'};
    opacity: ${props => (props.transparent ? 0.5 : 1)};
  }

  button {
    display: ${props => (props.alwaysShowNavigation ? 'block' : 'none')};
  }
`

export const SliderSubTitle = styled(Headline3)`
  font-weight: lighter;
  font-size: 0.75rem;
  line-height: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;

  ${media.laptop`
    font-size: 0.875rem;
  `}
`

const Subtitle = styled(Headline2)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
  line-height: 1.2;

  ${media.laptop`
    display: unset;
    justify-content: unset;
    align-items: unset;
    font-size: 1.25rem;
    text-align: left;
  `}
`

const ContentHeadline = styled(Headline3)`
  font-size: 1rem;
  font-weight: bold;
`

export const TagListContainerMobile = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: transparent;

  ${media.laptop`
    display: none;
  `}
`
export const TagListContainerDesktop = styled.div`
  display: none;

  ${media.laptop`
    margin: 1.25rem 0 0 0;
    display:block;
  `}
`

export const ContentInformationHeader = styled.div`
  position: relative;
  ${FlexColumnCenter}

  ${media.laptop`
    flex-direction: column-reverse;
    align-items: flex-start;
  `}
`
type HintBoxProps = {
  hasTagsAbove?: boolean
  hasNoCompatibleTonies?: boolean
}

export const HintBox = styled(InfoBox)<HintBoxProps>`
  margin-top: ${props =>
    props.hasTagsAbove && props.hasNoCompatibleTonies ? '5.75rem' : '1.5rem'};

  ${media.laptop`
    margin-top: ${props => (props.hasTagsAbove ? '1.5rem' : '0')};
    margin-left: 0;
  `}
`

const RuntimeWrapper = styled.div<{ isAudioCandy?: boolean }>`
  margin-top: ${props => (props.isAudioCandy ? '3rem' : '1rem')};
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`

const DescriptionWrapper = styled.div`
  ${media.laptop`
    grid-column: 1/2;
  `}
`

const HeadlineWrapper = styled.div`
  ${FlexColumnCenter}

  ${media.laptop`
    align-items: flex-start;
  `}
`

export const NoCompatibleTonieWrapper = styled.div`
  ${FlexColumnCenter}
`

export const NoCompatibleTonieText = styled(Text)`
  text-align: center;
  width: 80%;
  font-family: ${props => props.theme.fonts.serif};
  font-size: 1rem;
  font-weight: 300;

  ${media.laptop`
    width: 60%;
    hyphens: manual;
    margin-bottom: 1.5rem;
  `}
`

export const NoCompatibleTonieCTA = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row-reverse;

  ${media.laptop`
    flex-direction: column;
  `}
`

export const ShopLink = styled(OmniButton)`
  font-size: 0.875rem;

  ${media.laptop`
    margin-bottom: 1rem;
  `}
`

type AssignedTOnieAvatarProps = {
  showTonieInMyHouseholdIndicator?: boolean
}

export const AssignedTonieAvatar = styled.img<AssignedTOnieAvatarProps>`
  height: 4.75rem;
  width: 4.75rem;
  border-radius: 50%;
  object-fit: cover;
  background-color: ${props => props.theme.GrayLightest};
  position: relative;
  margin-top: ${props =>
    props.showTonieInMyHouseholdIndicator ? '2.5rem' : '1.5rem'};

  ${media.laptop`
    height: 6rem;
    width: 6rem;
    top: 2rem;
    margin-bottom: 1rem;
  `}
`

export const ContentContainer = styled.div`
  ${media.laptop`
    padding-right: 2rem;  
  `}
`

export const OptionsTitleWrapper = styled.span`
  position: relative;
  left: 0;
  label {
    margin: 0.625rem 1.5rem 0.625rem 1rem;
  }
`

interface AudioItem {
  title: string
  subtitle?: string
  series?: {
    name: string
  }
  languageUnicode?: string
}

export function LanguageLabel({ audioItem }: { audioItem: AudioItem }) {
  const { i18n } = useTranslation()
  return (
    <>
      {audioItem.languageUnicode &&
      audioItem.languageUnicode.slice(0, 2) !== i18n.language.slice(0, 2)
        ? `(${audioItem.languageUnicode.slice(0, 2).toUpperCase()}) `
        : ''}
    </>
  )
}

// used for paid content (tunes)
export function HeadlineSection({
  audioItem,
  subtitleDecoration,
}: {
  audioItem: AudioItem
  subtitleDecoration?: React.ReactNode
}) {
  const seriesName = selectTunesSeriesName(audioItem)

  return (
    <HeadlineWrapper>
      {seriesName && (
        <Title data-testid="series-headline">{seriesName.value}</Title>
      )}
      <Subtitle data-testid="series-title">
        {subtitleDecoration}
        <LanguageLabel audioItem={audioItem} />
        {audioItem.title}
      </Subtitle>
    </HeadlineWrapper>
  )
}

// the free content (audio candy) version uses different props than HeadlineSection
export function TitleSubtitleSection({ audioItem }: { audioItem: AudioItem }) {
  return (
    <HeadlineWrapper>
      <Title data-testid="title">
        <LanguageLabel audioItem={audioItem} />
        {audioItem.title}
      </Title>
      {audioItem.subtitle && (
        <Subtitle data-testid="subtitle">{audioItem.subtitle}</Subtitle>
      )}
    </HeadlineWrapper>
  )
}

type DescriptionProps = {
  authors: Array<{ name: string }>
  description: string
  length: string
  tracks?: Array<{
    seconds: number
    title: string
  }>
  audioSample?: ReactNode
  languageName?: string
  minAge?: number
}

function checkAndConvertTagContent(content: string) {
  return content.startsWith('genre-') ? content.replace('genre-', '') : content
}

function correctTag(content: string) {
  return content.startsWith('genre-') ? 'genre' : 'classification'
}

export function Description({
  authors,
  description,
  length,
  tracks,
  audioSample,
  languageName,
  minAge,
}: DescriptionProps) {
  const { t } = useTranslation(['audio-library-detail'])

  return (
    <DescriptionWrapper>
      <RuntimeWrapper>
        <ContentHeadline>{t('default:Content')}</ContentHeadline>
        <ContentLength data-testid="runtime">{length}</ContentLength>
      </RuntimeWrapper>
      {audioSample}
      <ContentDescription data-testid="description">
        {description}
      </ContentDescription>
      {authors.length > 0 && (
        <Paragraph size={2} data-testid="authors">
          {authors.length > 1 ? t('default:Authors') : t('default:Author')}
          {': '}
          {authors.map(author => author.name).join(', ')}
        </Paragraph>
      )}
      {languageName && (
        <Paragraph size={2} data-testid="content-language">
          {t('audio-library:LanguageFilterTitle')}
          {': '} {languageName}
        </Paragraph>
      )}
      {minAge && (
        // TODO: test id 'min-age-tag' is used in e2etests, remove when e2etests are refactored
        <Paragraph size={2} color="primary" data-testid="min-age-tag">
          {t('default:MinAge', { minAge })}
        </Paragraph>
      )}
      {/* TODO: use Spacing component or drop inline styles */}
      {(authors.length > 0 || languageName) && (
        // Spacing component requires children :F
        <div style={{ marginBottom: '1.5rem' }} />
      )}
      {tracks && tracks.length > 0 && (
        <Tracks
          tracks={tracks.map(track => {
            return {
              title: track.title,
              duration: formatAudioTime(track.seconds),
            }
          })}
        />
      )}
    </DescriptionWrapper>
  )
}

interface AudioLibraryTagListProps {
  tagListContent: {
    name: string
    slug: string
  }[]
  urlParams: AudioLibraryUrlState
  align?: 'left' | 'center'
  spacingLeft?: string
}

export const AudioLibraryTagList = ({
  tagListContent,
  urlParams,
  align,
  spacingLeft,
}: AudioLibraryTagListProps) => {
  return tagListContent.length > 0 ? (
    <TagListV2 align={align} spacingLeft={spacingLeft}>
      {tagListContent.map(item =>
        urlParams.webview === 'app' ? (
          <TagV2 key={item.name}>{checkAndConvertTagContent(item.name)}</TagV2>
        ) : (
          <TagV2
            key={item.name}
            data-trackingid={`${
              urlParams.activeTab === 'free-for-you' ? 'free-' : ''
            }content-detail__${correctTag(
              item.name
            )}-tag__tag-click-${checkAndConvertTagContent(item.name)}`}
            href={`/audio-library/${
              urlParams.activeTab === 'free-for-you'
                ? 'free-for-you?'
                : 'my-purchases?'
            }${correctTag(item.slug)}=${checkAndConvertTagContent(item.slug)}`}
          >
            {checkAndConvertTagContent(item.name)}
          </TagV2>
        )
      )}
    </TagListV2>
  ) : null
}
