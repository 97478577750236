import { Headline2, OmniButton, useSliderColumns } from '@boxine/tonies-ui'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { InnerContainer } from '../../layouts/LayoutV2'
import {
  AdditionalContentWrapper,
  HeaderLayout,
  StyledHorizontalScrollList,
} from '../SharedAudioLibraryStyles'
import { ResponsiveLinkButton } from '../visual-elements/responsive-button'

// this is need for the absolute positioning of the ForceLinkToShopOverlay
const PositioningWrap = styled.div`
  position: relative;
`

// the overlay should completely cover the slider
// also preventing clicks on the slider children
export const ForceLinkToShopOverlay = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  display: flex;
  justify-content: center;

  > a {
    margin: -0.5rem 2rem 0 2rem;
    height: auto;
    padding-top: 0.125rem;
    align-self: center;
  }
`

interface DiscoverMoreSliderProps {
  children: React.ReactNode
  headline?: string
  findMoreHref?: string
  'data-trackingid'?: string
  'data-testid'?: string
  hasNoBackgroundColor?: boolean
  padding?: string
  definedColumns?: {
    columnAmountDefault: number
    columnAmountMobileL: number
    columnAmountTablet: number
    columnAmountLaptop: number
  }
  scrollByOne?: boolean
  sliderType?: string
  forceLinkToShop?: boolean
}

export function DiscoverMoreSlider({
  children,
  findMoreHref,
  headline,
  'data-trackingid': trackingId,
  'data-testid': testId,
  hasNoBackgroundColor = false,
  padding,
  definedColumns = {
    columnAmountDefault: 2.5,
    columnAmountMobileL: 3.5,
    columnAmountTablet: 5.5,
    columnAmountLaptop: 6,
  },
  scrollByOne = true,
  sliderType = undefined,
  forceLinkToShop = false,
}: DiscoverMoreSliderProps) {
  const { t } = useTranslation()
  const columns = useSliderColumns(definedColumns)
  const hasHeader = headline && findMoreHref

  return (
    <AdditionalContentWrapper
      data-testid={testId}
      hasNoBackgroundColor={hasNoBackgroundColor}
      padding={padding}
    >
      {hasHeader && (
        <InnerContainer>
          <HeaderLayout>
            {headline && (
              <Headline2
                align="left"
                data-testid={`headline${sliderType ? '-' : ''}${
                  sliderType || ''
                }`}
              >
                {headline}
              </Headline2>
            )}

            {findMoreHref && React.Children.count(children) > columns && (
              <OmniButton
                data-trackingid={trackingId}
                href={findMoreHref}
                data-testid={`find-more-link${sliderType ? '-' : ''}${
                  sliderType || ''
                }`}
              >
                {t('default:FindMore')}
              </OmniButton>
            )}
          </HeaderLayout>
        </InnerContainer>
      )}
      <PositioningWrap>
        <StyledHorizontalScrollList
          scrollByOne={scrollByOne}
          columns={columns}
          transparent={forceLinkToShop}
          alwaysShowNavigation={
            !forceLinkToShop &&
            columns === definedColumns.columnAmountLaptop &&
            React.Children.count(children) > definedColumns.columnAmountLaptop
          }
        >
          {children}
        </StyledHorizontalScrollList>
        {forceLinkToShop && (
          <ForceLinkToShopOverlay>
            <ResponsiveLinkButton
              styling="primary"
              href={findMoreHref}
              dataTestId={testId + '-force-to-shop-link'}
              data-trackingid={trackingId + '-force-to-shop-link'}
            >
              {headline
                ? headline
                : t('audio-library-detail:AdditionalContent')}
              <br />
              {t('audio-library:InTheShop')}
            </ResponsiveLinkButton>
          </ForceLinkToShopOverlay>
        )}
      </PositioningWrap>
    </AdditionalContentWrapper>
  )
}
