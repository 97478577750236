import { http } from '../../http.service'
import { catchResponseError } from '../../utils/sentry'
import { FlagsResponse, MeResponse, Region, User } from './profileTypes'

export async function patchUser(nextUserData: Partial<User>) {
  const keycloakPayload: Partial<{
    firstName: string
    lastName: string
    playtimeRecommendations: boolean
    country: string
  }> = {}

  const payload: Partial<MeResponse> = {}

  if (typeof nextUserData.firstName === 'string') {
    keycloakPayload.firstName = nextUserData.firstName
    payload.firstName = nextUserData.firstName
  }

  if (typeof nextUserData.lastName === 'string') {
    keycloakPayload.lastName = nextUserData.lastName
    payload.lastName = nextUserData.lastName
  }

  if (typeof nextUserData.country === 'string') {
    keycloakPayload.country = nextUserData.country
    payload.country = nextUserData.country
  }

  if (typeof nextUserData.playtimeRecommendations === 'boolean') {
    keycloakPayload.playtimeRecommendations =
      nextUserData.playtimeRecommendations
  }

  if (typeof nextUserData.unicodeLocale === 'string') {
    payload.unicodeLocale = nextUserData.unicodeLocale
  }

  if (typeof nextUserData.profileImage === 'string') {
    payload.profileImage = nextUserData.profileImage
  }

  try {
    // TODO: Patching both `keycloak` and `cloudservices` seems to be an
    // anti-pattern here.
    // Maybe this is a task for `cloudservices` as the name of the user is stored here as well.

    if (Object.keys(keycloakPayload).length > 0) {
      await http.patch(
        `${process.env.REACT_APP_LOGIN_URL}/auth/realms/tonies/tonie-account/account/profile`,
        keycloakPayload
      )
    }

    if (Object.keys(payload).length > 0) {
      await http.patch<MeResponse>('/me', payload)
    }
  } catch (error) {
    catchResponseError('UserProfile#patchUser', error)

    throw error
  }
}

export async function fetchFlags() {
  try {
    const { data } = await http.get<FlagsResponse>(`/flags`)

    return {
      canBuyTunes: data.canBuyTunes,
      contentRegion: data.contentRegion,
      fileUploadDisabledForRegion: data.fileUploadDisabledForRegion,
    }
  } catch (error) {
    catchResponseError('UserProfile#fetchFlags', error)

    throw error
  }
}

export function omitEqualKeyValues(
  source: Record<string, any>,
  target: Record<string, any>
): Record<string, any> {
  const result = {}

  for (const key in target) {
    if (source[key] !== target[key]) {
      result[key] = target[key]
    }
  }

  return result
}

/**
 * Checks if a given value is a valid region.
 * @param value - The value to check.
 * @returns `true` if the value is a valid region, `false` otherwise.
 */
export function isRegion(value: string): value is Region {
  return (
    value === 'dach' ||
    value === 'uki' ||
    value === 'usa' ||
    value === 'fra' ||
    value === 'eur' ||
    value === 'hkg' ||
    value === 'row' ||
    value === 'can'
  )
}
